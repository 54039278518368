<template>
  <div>
    <div class="content">
      <div class="filter-wrapper" style="padding: 10px 0px 0px 0px">
        <input type="text" v-model="titleValue" placeholder="请输入政策标题关键字" class="el-input__inner" />
        <div class="global-search__btn" @click="search">
          <i class="icon-search"></i>
        </div>
      </div>
      <div class="filter-wrapper">
        <div class="filter-item">
          <div class="filter-item__label">服务分类：</div>
          <div class="filter-item__options" ref="type">
            <div v-for="(item, index) in ServiceCategory" :key="index" :class="item.active"
              @click="ServiceCategorySelect(index)">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="filter-item">
          <div class="filter-item__label">机构类型：</div>
          <div class="filter-item__options" ref="qwer">
            <div :class="item.active" v-for="(item, index) in InstitutionalType" :key="index"
              @click="InstitutionalTypeSelect(index)">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="policy-decode__list">
        <div class="el-row" style="margin: 0px; width: 100%">
          <div class="el-col el-col-6" style="padding-left: 10px; padding-right: 10px"
            v-for="(item, index) in PolicyDetails" :key="index" @click="showDetails(item.id)">
            <div class="topic-item">
              <div :title="item.title" class="title">{{ item.title }}</div>
              <div class="cover">
                <img :src="item.img" style="width: 100%" height="100%" />
              </div>
              <div class="flex row-between p-b-10 bottom-warp">
                <span class="light"><i class="el-icon-view m-r-5"></i>{{ item.pv }}</span>
                <span class="light"><i class="el-icon-date m-r-5"></i>{{ item.releaseDate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="
          width: 900px;
          margin: 20px auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: left;
        ">
        <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
        </el-pagination>
        <div>共{{ PolicyDetails.length }}条</div>
      </div>
    </div>
    <siteFooter></siteFooter>
  </div>
</template>
<script>
import request from "@/api/request";
import siteFooter from "@/components/siteFooter";
export default {
  name: "policyinterpretation",
  components: {
    siteFooter: siteFooter,
  },
  data() {
    return {
      ServiceCategory: [],
      InstitutionalType: [],
      PolicyDetails: [],
      pageSize: 10,
      total: 0,
      titleValue: ''
    };
  },
  async created() {
    await this.getOptions();
    await this.getPolicyList(
      this.ServiceCategory.filter(
        (item) => item.active == "active" && item.name != "不限"
      ).map((item) => item.id),
      this.InstitutionalType.filter(
        (item) => item.active == "active" && item.name != "不限"
      ).map((item) => item.id),
      this.pageSize,
      1
    );
  },
  methods: {
    search() {
      this.getPolicyList(
        this.ServiceCategory.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.InstitutionalType.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.pageSize,
        1
      );
    },
    getOptions() {
      request.get("/api/Policy/GetColumn").then((res) => {
        // console.log(res);
        var options = [...res.data.Data];
        let arr = options
          .filter((item) => item.parent_id == 30)
          .map((item) => {
            return {
              active: "",
              id: item.id,
              name: item.title,
            };
          });
        this.ServiceCategory = [
          { active: "active", id: 0, name: "不限" },
          ...arr,
        ];
        // console.log(this.ServiceCategory);

        arr = options
          .filter((item) => item.parent_id == 31)
          .map((item) => {
            return {
              active: "",
              id: item.id,
              name: item.title,
            };
          });
        this.InstitutionalType = [
          { active: "active", id: 0, name: "不限" },
          ...arr,
        ];
      });
    },
    getPolicyList(typeitem, rankitem, pageSize, pageIndex) {
      let url = "/api/Policy/GetInterpretation";

      let reqObj = {
        zclb: typeitem.join(","),
        zcjb: rankitem.join(","),
        keyword: this.titleValue,
        pagesize: pageSize,
        pageindex: pageIndex,
      };
      request.post(url, reqObj).then((res) => {
        this.total = res.data.Data.total;
        this.PolicyDetails = [
          ...res.data.Data.data.map((item) => {
            return {
              id: item.id,
              img: item.img_url,
              rank: item.Jibie,
              title: item.title,
              pv: item.click,
              releaseDate: this.formatDayTime(item.add_time),
            };
          }),
        ];
      });
    },
    async ServiceCategorySelect(index) {
      for (let i = 0; i < this.ServiceCategory.length; i++) {
        if (i == index) {
          this.ServiceCategory[index].active = "active";
        } else {
          this.ServiceCategory[i].active = "";
        }
      }
      this.getPolicyList(
        this.ServiceCategory.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.InstitutionalType.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.pageSize,
        1
      );
    },
    InstitutionalTypeSelect(index) {
      for (let i = 0; i < this.InstitutionalType.length; i++) {
        if (i == index) {
          this.InstitutionalType[index].active = "active";
        } else {
          this.InstitutionalType[i].active = "";
        }
      }
      this.getPolicyList(
        this.ServiceCategory.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.InstitutionalType.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.pageSize,
        1
      );
    },
    handleCurrentChange(val) {
      this.getPolicyList(
        this.ServiceCategory.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.InstitutionalType.filter(
          (item) => item.active == "active" && item.name != "不限"
        ).map((item) => item.id),
        this.pageSize,
        val
      );
    },
    formatDayTime(v) {
      return v.replace(/[a-zA-Z]/g, " ");
    },
    showDetails(id) {
      this.$router.push("/PolicyCalculation/policyInterpretationDeteils/" + id);
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.content {
  background-color: #ffffff;
  width: 950px;
  margin: 20px auto;
  padding-bottom: 20px;
  z-index: 99;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.active {
  background-color: #028dfb !important;
}

.filter-wrapper {
  width: 95%;
  margin: 0px auto;
  border-radius: 0;
  padding: 10px 0px 30px 0px;
}

.filter-wrapper .filter-item {
  display: flex;
  text-align: center;
  margin-bottom: 0.625rem;
  width: 100%;
}

.filter-wrapper .filter-item__label {
  font-size: 1rem;
  color: #333;
  margin-right: 1.25rem;
  margin-bottom: 0.625rem;
  white-space: nowrap;
  margin-top: 10px;
}

.filter-wrapper .filter-item__options {
  width: 800px;
  overflow: hidden;
}

.topic-item {
  color: #333;
  cursor: pointer;
  background: #fff;
  box-shadow: 0 0.1875rem 0.4375rem 0 rgba(0, 0, 0, 0.11);
  border-radius: 0.75rem;
  margin-bottom: 1.875rem;
  padding-top: 0.9375rem;
}

.topic-item .title {
  width: 80%;
  height: 2.6875rem;
  margin: 0 auto;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.filter-wrapper .filter-item__options div {
  justify-content: space-around;
  float: left;
  margin: 9px 8px 0px 10px;
  border-radius: 1.25rem !important;
  padding: 0.3375rem 0.9375rem;
  color: #fff;
  font-size: 0.875rem;
  background-color: #a3c8e5;
  user-select: none;
}

.policy-decode__list {
  width: 95%;
  margin: 0 auto;
  margin-top: 0.625rem;
}

.el-input__inner {
  width: 60%;
  border-radius: 1.25rem;
  height: 2.5rem;
  float: left;
}

.cover {
  height: 12rem;
  margin-bottom: 0.9375rem;
  border: 0.0625rem solid #f2f2f2;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 0.9375rem;
}

.topic-item .bottom-warp {
  font-size: 0.8125rem;
}

.p-b-10 {
  padding-bottom: 0.625rem;
}

.row-between {
  justify-content: space-between;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.topic-item .light {
  color: #c9c9c9;
  margin: 0 0.625rem;
}
</style>